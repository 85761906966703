<template>
    <div>
        <div class="no-right-box">
            <h2 v-html="msg"></h2>
        </div>
    </div>
</template>

<script>
export default {
    name: 'noRights',
    data() {
        return {
            msg: "403!<br> Sorry, you don't have access！"
        };
    },
};
</script>

<style lang="scss" scoped>
.no-right-box {
    margin: 0 auto;
    font-size: 16px;
    padding: 15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    h2 {
        text-align: left;
    }
}
</style>