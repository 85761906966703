<template>
    <div class="play">
        <iframe :src="gameurl"></iframe>
    </div>
</template>

<script>
export default {
    data() {
        return {
            gameurl: '',
            adItem: null,
        }
    },
    created() {
        const urlObj = new URLSearchParams(location.search)
        this.gameurl = urlObj.get('gameurl')
    },
    watch: {
    },
    mounted() {
    },
    methods: {
    }
}
</script>
<style lang="scss" scoped>
.play {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    iframe {
       width: 100%;
       height: 100%; 
    }
}
</style>
