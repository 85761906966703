<template>
  <div>
    <!-- 获取渠道商参数，根据参数获取权限 -->
    <div class="loading-box flex-center">
      <van-loading type="spinner" color="#fff" />
    </div>
  </div>
</template>

<script>
// import { getAuthChannel } from "@/api/index.js";
export default {
  name: 'loadingData',
  data() {
    return {

    };
  },
  created() {
    console.log('获取的渠道商参数==>' + this.$route.query.permission);

    // 存当前渠道商参数
    if(this.$route.query.permission) {
      localStorage.setItem('permission', this.$route.query.permission);
      localStorage.setItem('isLookGameMall', true);
      this.$router.push('/');
    }


    // 获取当前渠道商是否有查看游戏商城权益
    // getAuthChannel().then(res => {
    //     if(res.code == 200 && res.data) {
    //         localStorage.setItem('isLookGameMall', true);//当前渠道商，有权查看游戏商城
    //         this.$router.push('/');
    //     } else {
    //       localStorage.setItem('isLookGameMall', true);
    //       this.$router.push('/');
    //     }
    // }).catch(err => {
    //     console.log(err);
    //   this.$router.push('/');
    // })
  },
};
</script>

<style lang="scss" scoped>
.loading-box {
  height: 100vh;
  margin: 0 auto;
}
</style>