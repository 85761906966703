<template>
    <div class="game-list-all">
        <div class="game-body">
            <div class="game-box">
                <div class="game-item" @click="goDetialFn(item.id)" :id="item.id" v-for="(item, index) in detialMoreGame" :key="index">
                    <div class="img-box">
                        <img v-if="item.isNew == '1'" class="is-new" src="../image/new-img.gif" alt="">
                        <img :src="item.logo" class="to-default" alt="" />
                        <img v-if="item.isHot == '1'" class="is-hot" src="../image/new-fire-2.gif" alt="">
                    </div> 
                    <!-- <p class="flex-start">
                        <span class="game-name">{{ item.enName }}</span>
                        <van-icon v-if="item.isHot == '1'" name="fire" color="rgb(249 115 0)" />
                    </p> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex";

    export default {
        name: 'detialGamePage',
        data() {
            return {
                
            }
        },
        created() {

        },
        computed: {
            // 详情页展示的游戏列表
            ...mapState(['detialMoreGame']),
        },
        methods: {
            // 跳转详情页
            goDetialFn(id) {
                document.documentElement.scrollTop = 0;
                
                // 首页和详情页要分两个html页面
                location.href = '/detial.html?id=' + id;
                // this.$bus.$emit('goSelectedGameDetialFn', id)
            },
        },
    }
</script>

<style lang="scss" scoped>
/* 游戏主体 */
.game-body {
    padding: 15px 0 0;
  .game-box {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    // 正常一行4个展示的游戏
    .game-item {
       margin: 5px 0;
      padding: 0 3px;
      //width: 25%;
      border-radius: 0.3rem;
      .one-game {
        p {
          font-size: 10px;
        }
      }
      .img-box {
        position: relative;
        .to-default {
          width: 80px;
          height: 80px;
          border: 1px solid #ddd;
        }
        .to-big {
          width: 160px;
          height: 160px;
          border: 1px solid #ddd;
        }
        .is-new {
          position: absolute;
          right: 0;
          top: 0;
          width: 30px;
        }
        .is-hot {
          position: absolute;
          width: 20px;
          left: 0;
          bottom: 0;
        }



            }
            .game-name {
                margin-right: 2px;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            &.is-big {
                //width: 50%;
            }
            
        }
    }
}
</style>