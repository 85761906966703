import 'babel-polyfill'
import Vue from 'vue'
import store from './store' 
import router from './router' 

import Bus from './bus'
Vue.prototype.$bus = Bus 

// UI组件
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'

import Mint from 'mint-ui';
import 'mint-ui/lib/style.css';

import '@/plugins/vant'
import 'vant/lib/index.css'

// 移动端适配
import 'amfe-flexible'
import 'lib-flexible'
import App from './App.vue'

Vue.config.productionTip = false

Vue.use(ElementUI);
Vue.use(Mint);

// import Vconsole from 'vconsole'
// let vConsole = new Vconsole()
// Vue.use(vConsole);

new Vue({
  render: h => h(App),
  store,
  router
}).$mount('#app')
