<template>
  <div id="app" class="element">
    <router-view :key="key"></router-view>
  </div>
</template>

<script>
import Vue from 'vue'

// 使用mint-ui
import { Progress } from 'mint-ui'
Vue.component(Progress.name, Progress);

export default {
  name: 'App',
  data() {
      return {

      }
  },
  computed: {
    //设置组件动态key属性值，供详情页参数变化页面刷新
    key() {
      return this.$route.name !== undefined
        ? this.$route.name + new Date()
        : this.$route + new Date();
    }
  },
}
</script>

<style>
@import "./css/common.css";
</style>
