<template>
    <div class="box">
        <!-- 返回 -->
        <img src="../image/go-back.png" @click="goBack" alt="">
        <!-- 隐私政策 -->
        <div class="privacyP-policy">
            <h2>Privacy Policy</h2>
            <br>

            <h3><span>General and definitions</span></h3>
            <p>Game app as a Free app. This SERVICE is provided by at no cost and is intended for use as is.</p>
            <p>This page is used to inform visitors regarding my policies with the collection, use, and disclosure of Personal Information if anyone decided to use my Service.</p>
            <p>If you choose to use my Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that I collect is used for providing and improving the Service. I will not use or share your information with anyone except as described in this Privacy Policy.</p>
            <p>The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at All Games, DouYougame, heiheigame, game app unless otherwise defined in this Privacy Policy.</p>
            <br>
            
            <h3><span>Information Collection and Use</span></h3>
            <p>For a better experience, while using our Service, I may require you to provide us with certain personally identifiable information. The information that I request will be retained on your device and is not collected by me in any way.</p>
            <p>The app does use third party services that may collect information used to identify you.</p>
            <p>Link to privacy policy of third party service providers used by the app</p>
            <ul>
            <li><span>Google Play Services</span></li>
            <li><span>AdMob</span></li>
            <li><span>Google Analytics for Firebase</span></li>
            </ul>
            <br>

            <h3><span>Log Data</span></h3>
            <p>I want to inform you that whenever you use my Service, in a case of an error in the app I collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing my Service, the time and date of your use of the Service, and other statistics.</p>
            <br>

            <h3><span>Cookies</span></h3>
            <p>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.</p>
            <p>The data subject may, at any time, prevent the setting of cookies through our website by means of a corresponding setting of the Internet browser used, and may thus permanently deny the setting of cookies. Furthermore, already set cookies may be deleted at any time via an Internet browser or other software programs. This is possible in all popular Internet browsers. If the data subject deactivates the setting of cookies in the Internet browser used, not all functions of our website may be entirely usable.</p>
            <br>

            <h3><span>Security</span></h3>
            <p>I value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and I cannot guarantee its absolute security.</p>
            <br>

            <h3><span>Links to Other Sites</span></h3>
            <p>This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by me. Therefore, I strongly advise you to review the Privacy Policy of these websites. I have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>
            <br>

            <h3><span>Children’s Privacy</span></h3>
            <p>These Services do not address anyone under the age of 13. I do not knowingly collect personally identifiable information from children under 13. In the case I discover that a child under 13 has provided me with personal information, I immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact me so that I will be able to do necessary actions.</p>
            <br>

            <h3><span>Changes to This Privacy Policy</span></h3>
            <p>I may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. I will notify you of any changes by posting the new Privacy Policy on this page.</p>
            <p>This policy is effective as of 2023-01-01</p>
            <br>
            
            <h3><span>Contact Us</span></h3>
            <p>If you have any questions or suggestions about my Privacy Policy, do not hesitate to contact us.</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'privacyPolicy',
    data() {
        return {
            
        };
    },
    methods: {
        goBack() {
            console.log(222)
            this.$router.go(-1);
        }
    }
};
</script>

<style lang="scss" scoped>
.box {
    display: flex;
    align-items: flex-start;
    padding: 0 5px;
    img {
        width: 30px;
        cursor: pointer;
    }
    .privacyP-policy {
        font-size: 0.37333rem;
        width: 100%;
        white-space: normal;
        padding: 0 15px;
        margin: 0 10px 30px;
        border: 1px solid #fff;
        h2 {
            font-size: 18px;
            margin: 8px 0;
        }
    } 
}

</style>