<template>
    <div>
        <!-- 底部条款及说明部分 -->
        <div class="terms-box">
            <!-- 网站名称 -->
            <div class="name flex-center">
                <img src="../image/logo.png" alt="">
                <span v-text="nameMsg"></span>
            </div>
            <!-- 用户协议与隐私条款 -->
            <div class="terms-and-conditions">
                <span class="user-agreement" v-text="userAgreementMsg" @click="goUserAgreement"></span>
                <span class="shu">|</span>
                <span class="privacy-policy" v-text="privacyPolicyMsg" @click="goPrivacyPolicy"></span>
            </div>
            <!-- 底部版权 -->
            <div class="foot">
                <p v-text="footMsg"></p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'componentTermsAndConditions',
    data() {
        return {
            nameMsg: 'Online Game Mall',
            userAgreementMsg: 'User Agreement',
            privacyPolicyMsg: 'Privacy Policy',
            footMsg: 'Copyright © 2023 Online Game Mall. All rights Reserved.'
        };
    },
    methods:{
        // 跳转用户协议页面
        goUserAgreement() {
            this.$router.push({
                name: 'userAgreement',
            })
        },
        // 跳转隐私条款页面
        goPrivacyPolicy() {
            this.$router.push({
                name: 'privacyPolicy',
            })
        }
    }
};
</script>

<style lang="scss" scoped>
.terms-box {
    margin: 30px auto;
    width: 100%;
    font-size: 12px;
    text-align: center;
    line-height: 20px;
    .name {
        img {
            width: 20px;
            margin-right: 8px;
        }
    }
    .terms-and-conditions {
        .shu {
            margin: 0 8px;
        }
        .user-agreement {
            color: #f5b300;
            cursor: pointer;
        }
        .privacy-policy {
            color: #f5b300;
            cursor: pointer;
        }
    }
}
</style>