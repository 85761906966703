// axios 请求拦截器
import axios from "axios";//原生的axios

//用来拦截用的
axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
//创建一个单例
const http = axios.create({
    baseURL: '接口的前半部分加端口',
    timeout: 5000,//响应时间
    // headers:{"Content-Type":"application/json;charset=utf-8"},
})
const permission = localStorage.getItem('permission');//渠道商参数

//拦截器  -请求拦截
http.interceptors.request.use(config => {
    console.log('开始拦截请求======>')
    console.log(config.params);

    // 每个接口请求时都需要增加，渠道参数permission
    if(permission && permission != undefined && permission != 'undefined') {
        config.params = {
            ...config.params,
            permission: permission
        }
    }

    console.log('请求地址：' + config.url);
    console.log('请求参数：' + JSON.stringify(config.params));

    //部分接口需要token
    let token = localStorage.getItem('token');
    if (token) {
        config.headers.token = token;
        // config.headers ={
        // 'token':token
        // }
    }
    return config;
}, err => {
    return Promise.reject(err)
})

//拦截器  -响应拦截
http.interceptors.response.use(res => {
    console.log('开始拦截响应======>')
    if (res.data.code === 200) {  //这里读者们可以根据服务器返回的数据去自行修改
        console.log('200 --- 接口响应成功')
        return Promise.resolve(res.data)

    } else {
        console.log('err --- 接口响应失败')
        return Promise.reject(res.data)
    }
}, err => {
    return Promise.reject(err)
});

//整体导出
export default http;
