// 1. 导入 Vue
import Vue from 'vue'
// 按需导入vant-ui组件
import {Button, Icon, Popup, Cell, Toast, Loading } from 'vant'


// 注册组件
Vue.use(Button)
Vue.use(Icon)
Vue.use(Popup)
Vue.use(Cell)
Vue.use(Toast)
Vue.use(Loading)

