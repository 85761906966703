<template>
  <div class="search-box">
    <div class="search-body flex-between">
      <!-- 返回展示默认title -->
      <img class="go-back" @click="goBack" src="../image/go-back.png" alt="" />

      <!-- 根据关键字搜索游戏 -->
      <div class="search-inp">
        <input type="text" ref="$searchContent" />
        <!-- 搜索按钮 -->
        <i class="el-icon-search" @click="toSearchGame"></i>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
import { mapState, mapMutations } from "vuex";
export default {
  name: "component-search-new",
  // props: ["isShowSearchBox"],
  data() {
    return {};
  },
  computed: {
    ...mapState(["isShowSearchBox"]),
  },
  methods: {
    ...mapMutations(["updateIsShowSearchBox"]),
    goBack() {
      this.updateIsShowSearchBox(false);
    },
    toSearchGame() {
      let searchContent = this.$refs.$searchContent.value;
      // console.log('搜索框参数' + searchContent)
      if (searchContent) {
        this.$bus.$emit("searchGameResetDataFn", searchContent);
      } else {
        //提示请输入内容
        Toast("Please enter content!");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search-box {
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;
  height: 30px;
  padding: 0 10px;
  .search-body {
    width: 100%;
    height: 100%;
    img.go-back {
      width: 30px;
      cursor: pointer;
    }
    .search-inp {
      position: relative;
      height: 30px;
      width: 100%;
      margin-left: 30px;
      .el-icon-search {
        position: absolute;
        z-index: 2;
        color: #aaa;
        font-size: 24px;
        right: 8px;
        top: 50%;
        cursor: pointer;
        transform: translate(0, -50%);
      }
      input {
        width: 100%;
        height: 100%;
        outline: none;
        padding: 0 10px 0 10px;
        font-size: 18px;
        color: #333;
        background: #fff;
        border: 1px solid #fff;
        border-radius: 10px;
      }
    }
  }
}
</style>
