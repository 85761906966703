import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    menuAllListDate: [],//菜单列表
    gameAllListDate: [],//游戏列表
    oneGameListDate: [],//第一行三个游戏
    bodyGameListDate: [],//游戏主体页面数据，4+2/4+2/4+2...组合展示
    isShowSearchBox: false,//是否展示顶部搜索栏
    detialMoreGame: [],//详情页，更多游戏
  },
  mutations: {
    // 更新菜单列表数据
    updateMenuAllList(state, val) {
      state.menuAllListDate = val;
    },
    // 更新游戏列表数据
    updateGameAllList(state, val) {
      state.gameAllListDate = val;
    },
    // 更新第一行游戏数据
    updateOneGameList(state, val) {
      state.oneGameListDate = val;
    },
    // 更新游戏主体页面数据，4+2
    updateBodyGameList(state, val) {
      state.bodyGameListDate = val;
    },
    // 更新顶部搜索栏展示状态
    updateIsShowSearchBox(state, val) {
      state.isShowSearchBox = val;
    },
    // 更新详情页，更多游戏
    updateDetialMoreGame(state, val) {
      state.detialMoreGame = val;
    },
  }
})
export default store